import { FeatureCollection } from "geojson-iots";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import Style from "ol/style/Style.js";
import Stroke from "ol/style/Stroke.js";
import Fill from "ol/style/Fill.js";
import GeoJSON from "ol/format/GeoJSON.js";
import CircleStyle from "ol/style/Circle.js";
import TextStyle from "ol/style/Text.js";
import { colorForType, iife } from "./lib.js";
import { fontStyle } from "./font.js";

export const makeBuildingLayer = (buildings: FeatureCollection) => {
  const source = new VectorSource({
    features: new GeoJSON().readFeatures(buildings, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    }),
  });
  const layer = new VectorLayer({
    source,
    style: (feature, res) => {
      const props = feature.getProperties();
      const exist = "exist" in props && props.exist;
      const color = colorForType(props.type);
      const text = new TextStyle({
        font: fontStyle(12),
        text: props.folder,
        textBaseline: "bottom",
        offsetY: -10,
        fill: new Fill({
          color,
        }),
        stroke: new Stroke({
          width: 3,
          color: "white",
        }),
      });

      const image = iife(() => {
        if (exist) {
          return new CircleStyle({
            radius: 7,
            fill: new Fill({
              color,
            }),
            stroke: new Stroke({
              color: "white",
              width: 1,
            }),
          });
        }
        return new CircleStyle({
          radius: 6,
          fill: new Fill({
            color,
          }),
          stroke: new Stroke({
            color: "#5d5d5d",
            // color: "red",
            width: 2,
          }),
        });
      });

      if (res < 0.7) {
        return new Style({ image, text });
      }
      return new Style({ image });
    },
  });

  return layer;
};

export const makeZoneLayer = (zones: FeatureCollection) => {
  const source = new VectorSource({
    features: new GeoJSON().readFeatures(zones, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    }),
  });
  const layer = new VectorLayer({
    source,
    style: (feature) => {
      //   const props = feature.getProperties();
      //   const name = props.name;
      // const folder = props.folder;

      //   const text = new TextStyle({
      //     font: fontStyle(13),
      //     text: name,
      //     textBaseline: "bottom",
      //     fill: new Fill({
      //       color: "white",
      //     }),
      //     stroke: new Stroke({
      //       width: 2,
      //       color: "black",
      //     }),
      //   });

      return new Style({
        stroke: new Stroke({
          color: "#ff5600",
          width: 2,
        }),
        fill: new Fill({
          color: "#ffffff2b",
        }),
        // text,
      });
    },
  });

  return layer;
};
