import BaseLayer from "ol/layer/Base.js";
import SourceTileWMS from "ol/source/TileWMS.js";
import SourceXYZ from "ol/source/XYZ.js";
import LayerTile from "ol/layer/Tile.js";
import TileLayer from "ol/layer/Tile.js";
import OSM from "ol/source/OSM.js";
import { div, events, input, label } from "./dom.js";

const customName = [
  ["medina-marrakesh", "atelier cartographique", true],
  ["medina-marrakesh-ortho-1917_wms", "Ortho 1917", false],
  ["medina-marrakesh-ortho-1996_wms", "Ortho 1996", false],
];

const makeCustom = (layerName: string, name: string, visible: boolean) => {
  const layer = new LayerTile({
    visible,
    source: new SourceTileWMS({
      projection: "EPSG:3857",
      params: {
        LAYERS: layerName,
        VERSION: "1.3.0",
        TILED: true,
      },
      url: "https://wms.atelier-cartographique.be/service",
      crossOrigin: "Anonymous",
    }),
  });
  layer.set("name", name);
  return layer;
};

const acLayers = customName.map(([l, n, v]) => makeCustom(l, n, v));

const osmLayer = new TileLayer({
  source: new OSM(),
  visible: false,
});
osmLayer.set("name", "OpenStreeMap");

const gLayer = new TileLayer({
  source: new SourceXYZ({
    url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
  }),
  visible: false,
});
gLayer.set("name", "google satellite");

export const getBaseLayers = () => [...acLayers, osmLayer, gLayer];

export const baseLayerSwitcher = (root: HTMLElement) => {
  const layers = getBaseLayers();
  const elements = layers.map((layer) => {
    const name = layer.get("name");
    const checkbox = events(input("checkbox"), (add) =>
      add("change", () => {
        layer.setVisible(!layer.getVisible());
      })
    );
    checkbox.id = name;
    if (layer.getVisible()) {
      checkbox.setAttribute("checked", "");
    }
    return div("layer", checkbox, label(name, name));
  });

  root.append(div("bl-switch", ...elements));
  return layers;
};
