const elemFacto =
  <K extends keyof HTMLElementTagNameMap>(tag: K) =>
  (className: string, ...elements: (Element | Text)[]) => {
    const e = document.createElement(tag);
    e.setAttribute("class", className);
    e.append(...elements);
    return e;
  };

export const events = <E extends HTMLElement>(
  e: E,
  f: (
    s: <K extends keyof HTMLElementEventMap>(
      k: K,
      listener: (ev: HTMLElementEventMap[K]) => void
    ) => void
  ) => void
) => {
  const add = <K extends keyof HTMLElementEventMap>(
    k: K,
    listener: (ev: HTMLElementEventMap[K]) => void
  ) => {
    e.addEventListener(k, listener);
  };
  f(add);
  return e;
};

interface ToString {
  toString(): string;
}

export const attrs = <E extends HTMLElement>(
  e: E,
  f: (s: (k: string, v: ToString) => void) => void
) => {
  const set = (k: string, v: ToString) => {
    e.setAttribute(k, v.toString());
  };
  f(set);
  return e;
};

export const div = elemFacto("div");
export const span = elemFacto("span");
export const anchor = elemFacto("a");
export const baseInput = elemFacto("input");
const baseLabel = elemFacto("label");

export const img = (src: string) => {
  const e = document.createElement("img");
  e.setAttribute("src", src);
  return e;
};

export const link = (name: string, url: string, className = "") => {
  const e = anchor(className, text(name));
  e.setAttribute("href", url);
  return e;
};

export const input = (type: string, className = "") => {
  const e: HTMLInputElement = baseInput(className);
  e.setAttribute("type", type);
  return e;
};

export const label = (for_: string, txt: string, className = "") => {
  const e = baseLabel(className, text(txt));
  e.setAttribute("for", for_);
  return e;
};

export const text = (content: string) => document.createTextNode(content);

export function removeElement(elem: Node, keepChildren = false) {
  if (!keepChildren) {
    emptyElement(elem);
  }
  const parent = elem.parentNode;
  if (parent) {
    parent.removeChild(elem);
  }
}

export function emptyElement(elem: Node) {
  while (elem.firstChild) {
    removeElement(elem.firstChild);
  }
  return elem;
}
