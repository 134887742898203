import Feature from "ol/Feature.js";
import { Point, MultiPolygon } from "ol/geom.js";
import { div, emptyElement, events, img, link, text } from "./dom.js";
import { colnames, DirData, FType } from "./lib.js";

const renderProp = (
  props: {
    [x: string]: any;
  },
  key: string
) => {
  const value = props[key];
  if (value) {
    return div(
      "entry",
      div("key", text(key)),
      div("value", text(value as string))
    );
  }
  return div("entry empty", div("key", text(key)));
};

const make_thumbnails = (ftype: FType, folder: string, images: string[]) =>
  images.map((name: string) => {
    const image = img(
      `/thumbnail/${ftype}/${encodeURIComponent(folder)}/${encodeURIComponent(
        name
      )}`
    );
    image.setAttribute("loading", "lazy");
    // image.width = 300;
    image.height = 300;
    image.addEventListener("load", () => {
      image.height = image.naturalHeight;
    });
    return div(
      "thumbnail",
      div(
        "name",
        link(
          name,
          `/media/${ftype}/${encodeURIComponent(folder)}/${encodeURIComponent(
            name
          )}`
        )
      ),
      image
    );
  });

const make_documents = (ftype: FType, folder: string, documents: string[]) =>
  documents.map((name: string) =>
    div(
      "document",
      div(
        "name",
        link(
          name,
          `/media/${ftype}/${encodeURIComponent(folder)}/${encodeURIComponent(
            name
          )}`
        )
      )
    )
  );

export const renderSelectedPoint = (
  info: HTMLElement,
  feature: Feature<Point>,
  zoomToFeature: (feature: Feature<Point>) => void
) => {
  emptyElement(info);
  const props = feature.getProperties();
  const folder: string = props.folder;

  const es = [
    div(
      "folder",
      link(
        folder,
        `https://medina.atelier-cartographique.be/nextcloud/index.php/apps/files/?dir=/archive-bati/batiments/${encodeURIComponent(
          folder
        )}`
      )
    ),
    events(div("zoom", text("↬ sur la carte")), (add) =>
      add("click", () => {
        zoomToFeature(feature);
      })
    ),
  ]
    .concat(colnames.map((key) => renderProp(props, key)))
    .concat(make_documents("building", folder, props.documents))
    .concat(make_thumbnails("building", folder, props.images));

  props.dirs.map((name: string) => {
    const element = div(
      "dir",
      events(div("name", text(name)), (add) =>
        add("click", ({ target }) => {
          fetch(
            `/dir/building/${encodeURIComponent(folder)}/${encodeURIComponent(
              name
            )}`
          )
            .then((r) => r.json())
            .then((data: DirData) => {
              const ds = make_documents(
                "building",
                `${folder},${data.name}`,
                data.documents
              ).concat(
                make_thumbnails(
                  "building",
                  `${folder};${data.name}`,
                  data.images
                )
              );
              element.append(div("dirlist", ...ds));
            });
        })
      )
    );
    es.push(element);
  });
  info.append(...es);
};

export const renderSelectedPolygon = (
  info: HTMLElement,
  feature: Feature<MultiPolygon>
) => {
  emptyElement(info);
  const props = feature.getProperties();
  const folder: string | null = props.folder;
  if (folder === null) {
    return;
  }

  const es = [
    div(
      "folder",
      link(
        props.name ?? folder,
        `https://medina.atelier-cartographique.be/nextcloud/index.php/apps/files/?dir=/archive-bati/lieux-publics/${encodeURIComponent(
          folder
        )}`
      )
    ),
  ]
    .concat(make_documents("zone", folder, props.documents))
    .concat(make_thumbnails("zone", folder, props.images));

  props.dirs.map((name: string) => {
    const element = div(
      "dir",
      events(div("name", text(name)), (add) =>
        add("click", ({ target }) => {
          fetch(
            `/dir/zone/${encodeURIComponent(folder)}/${encodeURIComponent(
              name
            )}`
          )
            .then((r) => r.json())
            .then((data: DirData) => {
              const ds = make_documents(
                "zone",
                `${folder},${data.name}`,
                data.documents
              ).concat(
                make_thumbnails("zone", `${folder};${data.name}`, data.images)
              );
              element.append(div("dirlist", ...ds));
            });
        })
      )
    );
    es.push(element);
  });
  info.append(...es);
};
