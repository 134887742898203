const MAP_FONT_NAME = "u001";

export const fontStyle = (sz: number) => `${sz}px ${MAP_FONT_NAME}`;

export const loadFonts = (mapFontUrl: string) => {
  const mapFontFace = new FontFace(MAP_FONT_NAME, `url(${mapFontUrl})`);
  return mapFontFace.load().then((face) => {
    document.fonts.add(face);
    return face;
  });
};
