import { attrs, div, text } from "./dom.js";
import { COLORS } from "./lib.js";

export const legend = (root: HTMLElement) => {
  const items = Object.keys(COLORS).map((key) =>
    div(
      "item",
      attrs(div("marker"), (set) => set("style", `background: ${COLORS[key]}`)),
      div("label", text(key))
    )
  );
  root.append(div("legend", ...items));
};
