import { FeatureCollection } from "geojson-iots";

import { initMap, select } from "./map.js";
import { events, input, div, text } from "./dom.js";
import {
  onMapSelect,
  onMapSingleClick,
  onSearchSubmit,
} from "./interactions.js";
import { colnames } from "./lib.js";
import { loadFonts } from "./font.js";

const startApp = (
  root: HTMLElement,
  info: HTMLElement,
  buildings: FeatureCollection,
  zones: FeatureCollection
) => {
  const { map, zoomToFeature } = initMap(root, buildings, zones);

  map.addInteraction(select);
  select.on("select", onMapSelect(info, zoomToFeature));

  const coordBox = div("coords");
  root.append(coordBox);

  map.on("singleclick", onMapSingleClick(coordBox));

  const searchIndex: string[] = buildings.features.map(({ properties }) => {
    if (properties === null) {
      return "";
    }
    return (
      (properties.folder ?? " ") +
      colnames.map((k) => properties[k] ?? "").join(" ")
    ).toLowerCase();
  });

  const searchResult = div("result");
  const searchInput = events(input("text"), (add) =>
    add("keyup", (e) => {
      if (e.key === "Enter") {
        searchSubmit.click();
      }
    })
  );
  const searchSubmit = events(input("submit"), (add) =>
    add(
      "click",
      onSearchSubmit({
        searchResult,
        searchInput,
        searchIndex,
        buildings,
        zoomToFeature,
        info,
      })
    )
  );
  searchSubmit.value = "chercher";
  const search = div(
    "search",
    div("input", searchInput, searchSubmit),
    searchResult
  );

  root.append(search);
};

export const main = (
  root: HTMLElement,
  info: HTMLElement,
  buildings: FeatureCollection,
  zones: FeatureCollection,
  mapFontUrl: string
) => {
  loadFonts(mapFontUrl)
    .then(() => startApp(root, info, buildings, zones))
    .catch((err) =>
      root.append(div("error", text("Failed to load fonts: "), text(`${err}`)))
    );
};
