import { FeatureCollection } from "geojson-iots";
import Map from "ol/Map.js";
import View from "ol/View.js";

import Style from "ol/style/Style.js";
import Stroke from "ol/style/Stroke.js";
import Fill from "ol/style/Fill.js";
import Select from "ol/interaction/Select.js";
import CircleStyle from "ol/style/Circle.js";
import Feature from "ol/Feature.js";
import { Point } from "ol/geom.js";

import { makeBuildingLayer, makeZoneLayer } from "./layers.js";
import { baseLayerSwitcher, getBaseLayers } from "./base-layer.js";
import { legend } from "./legend.js";

export const initMap = (
  root: HTMLElement,
  buildings: FeatureCollection,
  zones: FeatureCollection
) => {
  const buildingLayer = makeBuildingLayer(buildings);
  const zonesLayer = makeZoneLayer(zones);

  const view = new View({
    center: [-889130.1936800188, 3714851.0144527974],
    zoom: 17,
  });

  const baseLayers = getBaseLayers();

  const map = new Map({
    target: root,
    layers: [...baseLayers, zonesLayer, buildingLayer],
    view,
  });

  //   map.on("singleclick", (event: { coordinate: [any, any] }) => {
  //     const [x, y] = event.coordinate;
  //     handler(view, x, y);
  //   });

  //   const toggleOsm = div("switch");
  //   toggleOsm.append(text("OSM"));
  //   toggleOsm.addEventListener("click", () => {
  //     if (osmLayer.getVisible()) {
  //       osmLayer.setVisible(false);
  //       toggleOsm.classList.remove("selected");
  //     } else {
  //       osmLayer.setVisible(true);
  //       toggleOsm.classList.add("selected");
  //     }
  //   });
  //   document.body.append(toggleOsm);

  baseLayerSwitcher(root);
  legend(root);

  const zoomToFeature = (f: Feature<Point>) => {
    const geom = f.getGeometry();
    if (geom) {
      const buf = 120;
      const [x, y] = geom.getCoordinates();
      const extent = [x - buf, y - buf, x + buf, y + buf];

      map.getView().fit(extent, {
        size: map.getSize(),
        // callback: () => {
        //   console.log("here", x, y);
        // },
      });
    }
  };

  return { map, zoomToFeature };
};

export const select = new Select({
  multi: false,
  style: (feature) =>
    feature.getGeometry()?.getType() === "MultiPolygon" ||
    feature.getGeometry()?.getType() === "Polygon"
      ? new Style({
          zIndex: 1000,
          stroke: new Stroke({
            color: "#1884f0",
            width: 4,
          }),
        })
      : new Style({
          zIndex: 1000,
          image: new CircleStyle({
            radius: 8,
            fill: new Fill({
              color: "#1884f0",
            }),
          }),
        }),
});
