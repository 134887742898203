import { toLonLat } from "ol/proj.js";

export type FType = "building" | "zone";

export const COLORS: Record<string, string> = {
  habitat: "#ff5722",
  religion: "green",
  eau: "blue",
  commerce: "purple",
};

export const colorForType = (typ: string | null) => {
  if (typ !== null) {
    const tc = typ.toLowerCase();
    if (tc in COLORS) {
      return COLORS[tc];
    }
  }
  return "black";
};

export const colnames = [
  //   "folder",
  "neighbourhood",
  "name",
  "type",
  "subtype",
  // "lat",
  // "lon",
  "firstname",
  "lastname",
  "year",
];

export type DirData = {
  name: string;
  images: string[];
  documents: string[];
};

export const formatCoord = (x: number, y: number) => {
  const [tx, ty] = toLonLat([x, y]);
  return `${tx.toFixed(5)} ${ty.toFixed(5)}`;
};

export const numberFormat = new Intl.NumberFormat("fr-BE", {
  maximumSignificantDigits: 8,
});

export const iife = <R>(fn: () => R) => fn();
