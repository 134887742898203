import { FeatureCollection } from "geojson-iots";
import { SelectEvent } from "ol/interaction/Select.js";
import { toLonLat } from "ol/proj.js";
import Feature from "ol/Feature.js";
import Map from "ol/Map.js";
import { MultiPolygon, Point } from "ol/geom.js";
import { renderSelectedPoint, renderSelectedPolygon } from "./info.js";
import { div, emptyElement, events, text } from "./dom.js";
import { numberFormat } from "./lib.js";
import GeoJSON from "ol/format/GeoJSON.js";

export const onMapSelect = (
  info: HTMLElement,
  zoomToFeature: (f: Feature<Point>) => void
) => {
  return ({ selected }: SelectEvent) => {
    if (selected.length > 0) {
      const feature = selected[0];
      const geom = feature.getGeometry();
      if (geom && geom.getType() === "Point") {
        renderSelectedPoint(info, feature as Feature<Point>, zoomToFeature);
      } else if (geom && geom.getType() === "MultiPolygon") {
        renderSelectedPolygon(info, feature as Feature<MultiPolygon>);
      }
    }
  };
};

// MapBrowserEvent

export const onMapSingleClick =
  (coordBox: HTMLElement) => (event: { coordinate: [any, any] }) => {
    const [x, y] = toLonLat(event.coordinate);
    emptyElement(coordBox);
    coordBox.append(
      events(
        div("longitude", text(" longitude "), text(numberFormat.format(x))),
        (add) =>
          add("click", () => {
            navigator.clipboard.writeText(numberFormat.format(x));
          })
      )
    );
    coordBox.append(
      events(
        div("latitude", text(" latitude "), text(numberFormat.format(y))),
        (add) =>
          add("click", () => {
            navigator.clipboard.writeText(numberFormat.format(y));
          })
      )
    );
  };

type onSearchSubmitOptions = {
  searchResult: HTMLElement;
  searchInput: HTMLInputElement;
  searchIndex: string[];
  buildings: FeatureCollection;
  zoomToFeature: (f: Feature<Point>) => void;
  info: HTMLElement;
};

export const onSearchSubmit =
  ({
    searchResult,
    searchInput,
    searchIndex,
    buildings,
    zoomToFeature,
    info,
  }: onSearchSubmitOptions) =>
  () => {
    emptyElement(searchResult);
    const input = searchInput.value.toLowerCase().trim();
    if (input.length > 1) {
      const pat = new RegExp(`.*${input}.*`);
      const reader = new GeoJSON();
      searchIndex.map((value, index) => {
        if (pat.test(value)) {
          const feature = buildings.features[index];
          const props = feature.properties!;
          searchResult.append(
            events(div("match", text(props.folder)), (add) =>
              add("click", () => {
                renderSelectedPoint(
                  info,
                  reader.readFeature(feature, {
                    dataProjection: "EPSG:4326",
                    featureProjection: "EPSG:3857",
                  }) as Feature<Point>,
                  zoomToFeature
                );
                searchInput.value = "";
                emptyElement(searchResult);
              })
            )
          );
        }
        return;
      });
    } else {
      searchResult.append(div("err", text("il me faut au moins 2 lettres")));
    }
  };
